.hero-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 40px;
    background-color: #0c0c0c;
    color: white;
  }
  
  .hero-content {
    max-width: 50%;
  }
  
  .hero-content h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .hero-content p {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .hero-content .highlight {
    color: #ff0000;
    font-weight: bold;
  }
  
  .hero-button {
    padding: 10px 20px;
    background-color: #ff0000;
    color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 5px;
  }
  
  .hero-button:hover {
    background-color: #cc0000;
  }
  
  .hero-image img {
    max-width: 100%;
    height: auto;
  }