  .product-card-home {
    width: 300px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s;
  }
  
  .product-card-home:hover {
    transform: translateY(-5px);
  }
  
  .product-image-home {
    height: 200px;
    width: auto;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .product-card-home h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .product-card-home p {
    font-size: 1.25rem;
    margin-bottom: 20px;
  }
  
  .add-to-cart-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .add-to-cart-btn:hover {
    background-color: #0056b3;
  }
  