/* Header Section */
.nav-header {
  list-style: none;
  display: flex;
  align-items: center; /* Vertically aligns the items */
  gap: 20px;
  padding: 10px 0; /* Add padding if needed */
}

.nav-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links li {
  display: inline;
}

.nav-links a {
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.nav-links a:hover {
  background-color: #ff7f50;
  border-radius: 5px;
}

.logo {
  height: 40px;
  width: auto;
}