.App {
  background-color: black;
  height: 100vh;
  width: 100vw;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
  text-align: center;
}

.App-logo {
  width: 8vw;
  height: auto;
}

.Home-top-container {
  display: flex;
  flex-direction: row;
}

.Home-image {
  width: 40vw;
  height: auto;
}

.Home-title-text {
  width: 40vw;
  color: white;
  font-family: 'nunito-sans';
  font-weight: bolder;
  font-size: 50px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}
