/* Basic Reset */
    
.grid-home {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
  }
    
  

    /* Product Grid */
    .product-grid-home {
        display: flex;
        flex-direction: column;
      padding: 50px 20px;
    }

    .product-grid-home h2 {
      text-align: center;
      font-size: 2rem;
      margin-bottom: 30px;
    }

  /* Body */
  body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f9;
    color: #333;
  }
  
  /* Footer */
  .footer {
    text-align: center;
    padding: 20px;
    background-color: #333;
    color: white;
  }
