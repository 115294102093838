.product-page {
    padding: 20px;
  }
  
  .sort-section {
    margin-bottom: 20px;
  }
  
  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .product-card {
    border: 1px solid #9b1414;
    border-radius: 8px;
    text-align: center;
    padding: 10px;
    background-color: #fff;
  }
  
  .product-image {
    width: 100%;
    height: auto;
  }
  
  .product-name {
    font-size: 1.2em;
    margin: 10px 0;
  }
  
  .product-price {
    color: #b12704;
    font-size: 1.1em;
  }
  
  .product-rating {
    color: #555;
  }