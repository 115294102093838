body {
    background-color: #1a1a1a;
    color: #e0e0e0;
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px;
    min-height: 100vh;
  }
  
  h1 {
    color: #ffffff;
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    background-color: #2e2e2e;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    color: #ffffff;
    margin-bottom: 8px;
    font-size: 1rem;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    background-color: #444444;
    border: none;
    border-radius: 5px;
    color: #e0e0e0;
    font-size: 1rem;
  }
  
  input::placeholder,
  textarea::placeholder {
    color: #b0b0b0;
  }
  
  textarea {
    resize: vertical;
    height: 120px;
  }
  
  .submit-btn {
    padding: 10px 20px;
    background-color: #00bfa5;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-btn:hover {
    background-color: #00897b;
  }
  